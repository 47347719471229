import React, {ReactNode, Suspense} from 'react'
import {
  Route,
} from 'react-router-dom'
import { Routes } from 'react-router'
import { Spin } from 'antd'
import { antIconSpin } from '../components/CleanComponents/Spinner'
import {useAppSelector} from "../ducks/hooks";


interface IRoute {
  path: string,
  component: ReactNode | JSX.Element | any,
  isPrivate?: boolean,
}

const loadableRoutes: IRoute[] = [
  // Default Pages
  {
    path: '/',
    component: React.lazy(() => import('../pages/default/login')),
  },
  {
    path: '/login',
    component: React.lazy(() => import('../pages/default/login')),
  },
  {
    path: '/business_outlines',
    component: React.lazy(() => import('../pages/businessOutline')),
    isPrivate: true,
  },
  {
    path: '/app_versions',
    component: React.lazy(() => import('../pages/appVersion')),
    isPrivate: true,
  },
  {
    path: '/pipelines',
    component: React.lazy(() => import('../pages/pipeline')),
    isPrivate: true,
  },
]

const AppRoutes = () => {
  const isLoggedIn = useAppSelector((state) => state.app.user.isLoggedIn)

  const suspense = (Component: any) => (
    <Suspense fallback={<Spin size="large" indicator={antIconSpin} spinning />}>
      <Component />
    </Suspense>
  )

  const renderPrivateRoute = (component: ReactNode) => {
    if (isLoggedIn) {
      return suspense(component)
    }
    return suspense(loadableRoutes.find(({ path }) => path === '/login')?.component)
  }

  const router = [
    ...loadableRoutes.map(({
      isPrivate,
      component,
      path,
    }) => ({
      key: path,
      path,
      caseSensitive: true,
      element: isPrivate ? renderPrivateRoute(component) : suspense(component),
    })),
    {
      key: '*',
      path: '*',
      element: suspense(React.lazy(() => import('../pages/default/notFound'))),
    },

  ]

  return (
    <Routes>
      {router.map((props) => <Route {...props} />)}
    </Routes>
  )
}

export default AppRoutes
