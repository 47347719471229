import dayjs from 'dayjs'
import { serializeParamOrderRequest } from '../format'
import { axiosInstance } from '../client'
import { endpoint } from '../endpoint'

export enum NotifyType {
  Info = 'info',
  Error = 'error',
  Success = 'success'
}

export interface INotification {
  id: number,
  '@id'?: string,
  '@type'?: string,
  createdAt: dayjs.Dayjs | string,
  title?: string,
  content: string,
  link?: string | null,
  type?: NotifyType,
  seen?: boolean,
}

export interface INotificationType {
  id: number,
  '@id'?: string,
  '@type': 'NotificationType',
  name: string,
  code: string,
  sendSms: boolean,
  sendEmail: boolean,
  sendPush: boolean,
  sendNotification: boolean,
  sendTelegram: boolean
}

export interface INotificationSubscriber {
  id?: number,
  '@id'?: string,
  '@type'?: 'NotificationTypeSubscribe',
  user: string,
  notificationType: string,
  sendSms: boolean,
  sendEmail: boolean,
  sendPush: boolean,
  sendNotification: boolean,
  sendTelegram: boolean
}

export interface INotificationSorter {
  id?: 'asc' | 'desc',
  type?: 'asc' | 'desc',
  seen?: 'asc' | 'desc',
  createdAt?: 'asc' | 'desc',
}

export interface INotificationFilter {
  id?: number,
  type?: NotifyType,
  seen?: boolean,
  employee?: string | number | null,
  subsidiary?: string,
  createdAt?: dayjs.Dayjs
}

export interface INotificationsListResponse {
  '@context': string,
  '@id': string,
  '@type': string,
  hydraMember: Array<INotification>,
  hydraSearch?: any,
  hydraTotalItems: number,
  hydraView?: any,
}

export interface INotificationsTypeListResponse {
  '@context': string,
  '@id': string,
  '@type': string,
  hydraMember: Array<INotificationType>,
  hydraSearch?: any,
  hydraTotalItems: number,
  hydraView?: any,
}

export interface INotificationsTypeSubscriberListResponse {
  '@context': string,
  '@id': string,
  '@type': string,
  hydraMember: Array<INotificationSubscriber>,
  hydraSearch?: any,
  hydraTotalItems: number,
  hydraView?: any,
}

export default {
  list: (
    sorter: INotificationSorter,
    filter: INotificationFilter,
    page = 1,
    itemsPerPage = 3,
  ) => axiosInstance<INotificationsListResponse>({
    method: 'get',
    url: endpoint.notifications.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(sorter),
      ...filter,
    },
  }),
  update: (id: number, data: INotification) => axiosInstance<INotification>({
    method: 'put',
    url: endpoint.notifications.one(id),
    data,
  }),
  delete: (id: number) => axiosInstance({
    method: 'delete',
    url: endpoint.notifications.one(id),
  }),
  seen: () => axiosInstance({
    method: 'post',
    url: endpoint.notifications.seen,
  }),
  subscribes: {
    list: (
      sorter: any,
      filter: {
        user?: string,
      },
      page = 1,
      itemsPerPage = 3,
    ) => axiosInstance<INotificationsTypeSubscriberListResponse>({
      method: 'get',
      url: endpoint.notifications.subscribes.list,
      params: {
        page,
        itemsPerPage,
        ...serializeParamOrderRequest(sorter),
        ...filter,
      },
    }),
    create: (data: INotificationSubscriber) => axiosInstance<INotificationSubscriber>({
      method: 'post',
      url: endpoint.notifications.subscribes.list,
      data: {
        ...data,
      },
    }),
    update: (id: number, data: INotificationSubscriber) => axiosInstance<INotificationSubscriber>({
      method: 'put',
      url: endpoint.notifications.subscribes.one(id),
      data,
    }),
  },
  type: {
    list: (
      sorter: any,
      filter: any,
      page = 1,
      itemsPerPage = 3,
    ) => axiosInstance<INotificationsTypeListResponse>({
      method: 'get',
      url: endpoint.notifications.type.list,
      params: {
        page,
        itemsPerPage,
        ...serializeParamOrderRequest(sorter),
        ...filter,
      },
    }),
  },
}
