import {ProLayout, ProSettings, SettingDrawer} from '@ant-design/pro-layout'
import { ProConfigProvider } from '@ant-design/pro-provider'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import defaultProps from './_defaultProps'
import Notification from '../../TopBar/Notification'
import shortLogo from '../../Menu/short-logo.png'
import Routes from '../../../../config/routes'
import { setLayoutState } from '../../../../ducks/app'
import { useAppDispatch, useAppSelector } from '../../../../ducks/hooks'
import { MenuItem } from '../../Menu/MenuLeft/menuData'

const MainLayout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const settings = useAppSelector((state) => state.app.settings.layoutState)
  const dispatch = useAppDispatch()

  const [showLiveSearch, setShowLiveSearch] = useState(false)

  return (
    <div id="pro-layout" style={{ minHeight: '100vh' }}>
      <ProConfigProvider hashed>
        <ProLayout
          {...defaultProps}
          location={{ ...location }}
          title="Reservit"
          logo={<img src={shortLogo} alt="Reservit - CRM booking system" />}
          siderWidth={255}
          collapsed={settings.menuCollapsed}
          onCollapse={() => dispatch(setLayoutState({ ...settings, menuCollapsed: !settings.menuCollapsed }))}
          menu={{
            collapsedShowGroupTitle: true,
          }}
          actionsRender={(props: any) => {
            if (props.isMobile) return []
            return [
              <Notification />,
              // <ProfileMenu />,
            ]
          }}
          headerTitleRender={(logo: any, title: any, _: any) => {
            const defaultDom = (
              <a>
                {logo}
                {title}
              </a>
            )
            if (document.body.clientWidth < 1400) {
              return defaultDom
            }
            if (_.isMobile) return defaultDom
            return (
              <>
                {defaultDom}
                {/* <MenuCard /> */}
              </>
            )
          }}
          // menuHeaderRender={() => !settings.menuCollapsed && (<SmallCalendar theme={settings.navTheme} />)}
          menuItemRender={(item: any, dom: any) => (
            <div onClick={() => { navigate(item.path || '/welcome') }}>
              {dom}
            </div>
          )}
          menuFooterRender={(props: any) => {
            if (props?.collapsed) return undefined
            return (
              <div
                style={{
                  textAlign: 'center',
                  paddingBlockStart: 12,
                }}
              >
              </div>
            )
          }}
          onMenuHeaderClick={() => navigate('/')}
          {...settings}
        >
          <Routes />
          <SettingDrawer
            pathname={location.pathname}
            enableDarkTheme
            getContainer={() => document.getElementById('pro-layout')}
            settings={settings}
            onSettingChange={(changeSetting: ProSettings) => {
              dispatch(setLayoutState({ ...settings, ...changeSetting }))
            }}
            disableUrlParams={false}
          />
        </ProLayout>
      </ProConfigProvider>
    </div>
  )
}

export default MainLayout
