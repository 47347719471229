import React, {useState} from 'react'
import {ConfigProvider} from 'antd'
import antdRU from 'antd/locale/ru_RU'
import antdEN from 'antd/locale/en_US'
import {useTranslation} from 'react-i18next'
import {defaultLanguage} from '../../config/i18next'

export const currencyConfigSuffixes = {
    ru: ' руб',
    en: ' rub',
}

export function dateLocalization(format: string) {
    switch (format) {
        case 'DD.MM.YYг. в h:mm':
            return 'DD.MM.YY at h:mm'
        default:
            return format
    }
}

function ConfigProviderLocalization({children}: any) {
    const {i18n} = useTranslation()

    const selectAntLocal = (lng: string) => {
        switch (lng) {
            case 'ru':
                return antdRU
            default:
                return antdEN
        }
    }
    const [currentAntdLocal, setCurrentAntdLocal] = useState(selectAntLocal(defaultLanguage))

    return (
        <ConfigProvider
            locale={currentAntdLocal}
            theme={{
                token: {
                    borderRadius: 0,
                    wireframe: true,
                },
            }}
        >
            {children}
        </ConfigProvider>
    )
}

export default ConfigProviderLocalization
