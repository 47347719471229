import applyCaseMiddleware from 'axios-case-converter'
import axios from 'axios'
import {notification} from 'antd'
import {API_ENDPOINT} from './endpoint'

// @todo: разделить код и убрать эти функции inject
let store: any
let logout: any
let refresh: any
export const injectStore = (_store: any) => {
  store = _store
}
export const injectLogout = (_logout: any) => {
  logout = _logout
}
export const injectRefresh = (_refresh: any) => {
  refresh = _refresh
}

export const readCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  ))
  return matches && decodeURIComponent(matches[1])
}

export const axiosInstance = applyCaseMiddleware(
  axios.create({
    timeout: 20000,
    baseURL: API_ENDPOINT,
  }),
  {
    preservedKeys: ['@id', '@type'],
    caseMiddleware: {
      requestInterceptor: (config: any) => config,
    },
  },
)

axiosInstance.defaults.headers.common.Authorization = `Bearer ${readCookie('app.token')}`

export const cleanIri = (iri: string) => {
  if (!iri) return iri

  const apiUrlParts = API_ENDPOINT!.split('/').reverse()
  const iriParts = iri.split('/').reverse()

  const cleanIriParts = []
  while (iriParts[0] && !apiUrlParts.find((x) => x === iriParts[0])) {
    cleanIriParts.push(iriParts[0])
    iriParts.shift()
  }

  return cleanIriParts.reverse().join('/')
}

axiosInstance.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const originalRequest = error.config

    // если ответили что не авторизован
    if (error.response.status === 401
      && !originalRequest._retry
      && !/\/token\/refresh/.test(originalRequest.url)
    ) {
      originalRequest._retry = true

      const res = await store.dispatch(refresh({login: store.getState().app.user.auth.refreshToken}))
      originalRequest.headers.Authorization = `Bearer ${res.payload.token}`

      return axiosInstance(originalRequest)
    }

    if (error.message === 'Network Error') {
      notification.error({
        message: 'Ошибка соединение с сервером',
        description: 'Сервер недоступен',
      })
    }

    return Promise.reject(error)
  },
)
