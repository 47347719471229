import React, { FC } from 'react'
import { Layout as AntLayout } from 'antd'
import Routes from '../../../../config/routes'

const AntContent = AntLayout.Content

const LoginLayout: FC = () => (
  <AntLayout>
    <AntContent style={{ minHeight: '100vh' }}>
      <Routes />
    </AntContent>
  </AntLayout>
)

export default LoginLayout
