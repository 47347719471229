import React, {useEffect} from 'react'
import {ContainerQuery} from '@postermywall/react-container-query'
import classNames from 'classnames'
import './style.scss'
import LoginLayout from './Login'
import MainLayout from './Main'
import {useAppDispatch, useAppSelector} from '../../../ducks/hooks'
import {initAuth} from '../../../ducks/app'

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
}

const Layout: React.FC = () => {
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector((state: any) => state.app.user.isLoggedIn)

  useEffect(() => {
    dispatch(initAuth())
  }, [dispatch])

  const renderLayout = () => {
    if (!isLoggedIn) {
      return <LoginLayout/>
    }
    return <MainLayout/>

    // return <MainLayout isMonitor={isMonitor} />
  }

  return (
    <ContainerQuery query={query}>
      {(params) => (
        <div className={classNames(params)}>
          {renderLayout()}
        </div>
      )}
    </ContainerQuery>
  )
}

export default Layout
