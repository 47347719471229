import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import humanize from 'humanize-duration'
import {defaultLanguage} from './i18next'

dayjs.extend(weekday)
dayjs.locale(defaultLanguage)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)


export const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, (causes 2500.99 to be printed as $2,501)
})

export const InputNumberFormatterPrice = (price: number | undefined) => price ? `${new Intl.NumberFormat('ru-RU').format(price)} руб` : ''

export const dateFormat = (value: string | dayjs.Dayjs, format: string | undefined = undefined) => dayjs(value).format('DD.MM.YYYY HH:mm')

export const humanizeDuration = (minutes: number) => humanize(minutes * 60 * 1000, {language: defaultLanguage})

export const serializeParamOrderRequest = (order: any) => {
    const orderParams = {}

    Object.keys(order).forEach((key) => {
        // @ts-ignore
        orderParams[`order[${key}]`] = order[key]
    })

    return orderParams
}
