import i18next from 'i18next'
import React from 'react'
import {
  BarsOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons'

export interface MenuItem {
  name: string | undefined,
  key: string | undefined,
  path?: string,
  component?: string,
  icon?: React.ReactNode,
  disabled?: boolean,
  children?: Array<{
    key: string,
    exact?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    name: string;
    path: string;
    // Optional secondary menu
    children?: MenuItem['children'];
  }>;
}

// @todo: тут надо указывать не url, а ссылку на routers/index loadableRoutes. В нем уже есть casl
const menuData = (): MenuItem[] => [
  {
    name: 'Бизнес-контуры',
    key: 'businessOutlines',
    path: '/business_outlines',
    icon: <BarsOutlined />,
  },{
    name: 'Версии приложений',
    key: 'appVersions',
    path: '/app_versions',
    icon: <BarsOutlined />,
  },{
    name: 'Пайплайны',
    key: 'pipelines',
    path: '/pipelines',
    icon: <BarsOutlined />,
  },
]

export default menuData()

export function reduceWithAbilities() {
  return menuData()
}
