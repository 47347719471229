export const  API_ENDPOINT  = process.env.REACT_APP_API_ENDPOINT
console.log(process.env)

export const endpoint = {
  docs: `${API_ENDPOINT}/docs.json`,
  login: `${API_ENDPOINT}/login`,
  refreshToken: `${API_ENDPOINT}/token/refresh`,
  notifications: {
    list: `${API_ENDPOINT}/notifications`,
    one: (id: number) => `${API_ENDPOINT}/notifications/${id}`,
    seen: `${API_ENDPOINT}/notifications/seen`,
    subscribes: {
      list: `${API_ENDPOINT}/notification_type_subscribes`,
      one: (id: number) => `${API_ENDPOINT}/notification_type_subscribes/${id}`,
    },
    type: {
      list: `${API_ENDPOINT}/notification_types`,
    },
  },
  businessOutline: {
    one: (id: number) => `${API_ENDPOINT}/business_outlines/${id}`,
    list: `${API_ENDPOINT}/business_outlines`,
  },
  businessOutlineLog: {
    list: `${API_ENDPOINT}/business_outline_logs`,
  },
  app: {
    list: `${API_ENDPOINT}/apps`,
  },
  appVersion: {
    list: `${API_ENDPOINT}/app_versions`,
  },
  pipeline: {
    list: `${API_ENDPOINT}/pipelines`,
  },
  businessOutlineAttributeValue: {
    list: `${API_ENDPOINT}/business_outline_attribute_values`,
  },
  businessOutlineStatus: {
    list: `${API_ENDPOINT}/business_outline_statuses`,
  },
  serviceDomain: {
    list: `${API_ENDPOINT}/service_domains`,
    one: (id: number | string) => `${API_ENDPOINT}/service_domains/${id}`,
  }
}
