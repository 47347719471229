import {axiosInstance} from "../client";
import {serializeParamOrderRequest} from "../format";
import {endpoint} from "../endpoint";
import {IBusinessOutline} from "../../ducks/model/businessOutline";

export default {
  list: (order = {}, filter = {}, page = 1, itemsPerPage = 30) => axiosInstance({
    method: 'get',
    url: endpoint.businessOutline.list,
    params: {
      page,
      itemsPerPage,
      ...serializeParamOrderRequest(order),
      ...filter,
    },
  }),
  get: (id: number) => axiosInstance({
    method: 'get',
    url: endpoint.businessOutline.one(id),
  }),
  delete: (id: number) => axiosInstance({
    method: 'delete',
    url: endpoint.businessOutline.one(id),
  }),
  create: (fields: any) => (axiosInstance({
    method: 'post',
    url: endpoint.businessOutline.list,
    data: {
      ...fields,
    },
  })),
  update: (id: number, fields: IBusinessOutline) => (axiosInstance({
    method: 'put',
    url: endpoint.businessOutline.one(id),
    data: {
      ...fields,
    },
  })),
  logs: {
    list: (id: number, order = {}, filter = {}, page = 1, itemsPerPage = 30) => {
      const filterBusinessOutline = {...filter, 'business_outline': id}
      return axiosInstance({
        method: 'get',
        url: endpoint.businessOutlineLog.list,
        params: {
          page,
          itemsPerPage,
          ...serializeParamOrderRequest(order),
          ...filterBusinessOutline,
        },
      })
    },

  },
  status: {
    list: (order = {}, filter = {}, page = 1, itemsPerPage = 30) => {
      return axiosInstance({
        method: 'get',
        url: endpoint.businessOutlineStatus.list,
        params: {
          page,
          itemsPerPage,
          ...serializeParamOrderRequest(order),
          ...filter,
        },
      })
    },
  },
  attributes: {
    list: (id: number, order = {}, filter = {}, page = 1, itemsPerPage = 30) => {
      const filterBusinessOutline = {...filter, 'business_outline': id}
      return axiosInstance({
        method: 'get',
        url: endpoint.businessOutlineAttributeValue.list,
        params: {
          page,
          itemsPerPage,
          ...serializeParamOrderRequest(order),
          ...filterBusinessOutline,
        },
      })
    },
  },

}
