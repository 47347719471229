import {axiosInstance} from "../client";
import IEntity from "../../ducks/model/common";
import {serializeParamOrderRequest} from "../format";

export default {
  get: (iri: string) => axiosInstance.get(iri)
}

interface IHydraListResponse<T> {
  'hydraMember': T[],

}

export const readService = <T extends IEntity>(endpoints: { one: (id: string | number) => string, list: string }) => {
  return {
    list: (order = {}, filter = {}, page = 1, itemsPerPage = 30) => axiosInstance<IHydraListResponse<T>>({
      method: 'get',
      url: endpoints.list,
      params: {
        page,
        itemsPerPage,
        ...serializeParamOrderRequest(order),
        ...filter,
      },
    }),
  }
}
