import React, { useEffect, useState } from 'react'
import {
  Badge,
  Button, Drawer, List, Spin, Tabs, Typography,
} from 'antd'
import {
  CheckOutlined, DeleteOutlined, IssuesCloseOutlined, SettingOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { AxiosResponse } from 'axios'
import {
  INotification,
  INotificationFilter,
  INotificationsListResponse,
  INotificationSorter,
} from '../../../../config/services/notification'
import { dateFormat } from '../../../../config/format'
import { antIconSpin } from '../../../CleanComponents/Spinner'
import API from '../../../../config'
import { useAppSelector } from '../../../../ducks/hooks'

interface Props {
  unread: number,
  setUnread: (unread: number) => void,
  isOpen: boolean,
  onClose: () => void,
  onLoad?: (
    sorter: INotificationSorter,
    filter: INotificationFilter,
    page: number,
    itemsPerPage: number,
  ) => Promise<AxiosResponse<INotificationsListResponse>>,
  onUpdate?: (id: number, data: INotification) => Promise<AxiosResponse<INotification>>,
  onDelete?: (id: number) => Promise<any>,
  onSeenAll?: () => Promise<any>,
}
type Pagination = {
  page: number,
  total: number,
  seen: boolean|undefined,
}

const NotificationDrawer: React.FC<Props> = ({
  unread,
  setUnread,
  isOpen,
  onClose,
  onLoad = API.notification.list,
  onUpdate = API.notification.update,
  onDelete = API.notification.delete,
  onSeenAll = API.notification.seen,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<number|null>(null)
  const [isAllSeen, setIsAllSeen] = useState<boolean>(false)
  const [items, setItems] = useState<INotification[]>([])
  const [pagination, setPagination] = useState<Pagination>({ page: 1, total: 0, seen: undefined })

  const onLoadNotification = () => {
    setIsLoading(true)
    onLoad(
      { id: 'desc' },
      {
        seen: pagination.seen,
      },
      pagination.page,
      10,
    )
      .then((response) => {
        if (pagination.page > 1) {
          setItems([...items, ...response.data.hydraMember])
        } else {
          setItems(response.data.hydraMember)
        }
        setPagination({
          ...pagination,
          page: pagination.page + 1,
          total: response.data.hydraTotalItems,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    onLoadNotification()
  }, [pagination.seen])

  const onMarkRead = (notification: INotification) => {
    onUpdate(notification.id, {
      ...notification,
      seen: true,
    }).then(() => {
      setItems(items.map((item) => (
        item.id === notification.id
          ? { ...notification, seen: true }
          : item
      )))
      setUnread(unread - 1)
    })
  }

  const onMarkReadAll = () => {
    setIsAllSeen(true)
    onSeenAll()
      .then(() => {
        setItems(items.map((item) => (
          {
            ...item,
            seen: true,
          }
        )))
        setUnread(0)
      }).finally(() => {
        setIsAllSeen(false)
      })
  }

  const onRemove = (notification: INotification) => {
    setIsDeleting(notification.id)
    onDelete(notification.id)
      .then(() => {
        setItems(items.filter((item) => item.id !== notification.id))
      })
      .finally(() => {
        if (!notification.seen) {
          setUnread(unread - 1)
        }
        setIsDeleting(null)
      })
  }

  return (
    <Drawer
      title={t('account:Notifications')}
      extra={(
        <Button
          size="small"
          type="text"
          style={{
            verticalAlign: 'middle',
            float: 'right',
          }}
          onClick={() => {
            onClose()
            navigate('/settings/notifications')
          }}
        >
          <SettingOutlined />
        </Button>
      )}
      closable
      onClose={onClose}
      open={isOpen}
      width=""
      rootClassName="drawer-md"
    >
      <Tabs
        defaultActiveKey="1"
        size="large"
        items={[
          {
            key: '1',
            label: 'Все',
          },
          {
            key: '2',
            label: (<Badge count={unread} offset={[20, -2]} showZero={false}>Не прочитанные</Badge>),
          },
        ]}
        tabBarExtraContent={{
          right: (
            <Button
              hidden={unread === 0}
              onClick={onMarkReadAll}
              loading={isAllSeen}
              type="link"
            >
              отметить все как прочитанные
            </Button>
          ),
        }}
        onChange={(activeKey) => {
          if (activeKey === '1') {
            setPagination({ page: 1, total: 0, seen: undefined })
          } else {
            setPagination({ page: 1, total: 0, seen: false })
          }
        }}
      />
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={onLoadNotification}
        hasMore={items.length < pagination.total && !isLoading}
        useWindow={false}
        loader={(
          <div key="notifications_spin" className="text-center">
            <Spin indicator={antIconSpin} spinning />
          </div>
        )}
      >
        <div className="notification__wrapper__list">
          <List<INotification>
            dataSource={items}
            renderItem={(item: INotification) => (
              <div key={item.id} className={`notification__wrapper ${item.seen ? '' : 'notification__wrapper-gray'}`}>
                <List.Item
                  extra={[
                    <Button
                      key="btn_mark_read"
                      hidden={item.seen}
                      icon={<CheckOutlined />}
                      type="link"
                      onClick={() => onMarkRead(item)}
                      title="Пометить как прочитанное"
                    />,
                    <Button
                      key="btn_delete"
                      icon={<DeleteOutlined />}
                      type="link"
                      loading={isDeleting === item.id}
                      onClick={() => onRemove(item)}
                      title="Удалить"
                      danger
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<IssuesCloseOutlined style={{ fontSize: '25px' }} />}
                    title={<div className="notification__item__title">{item.title}</div>}
                    description={(
                      <div>
                        <div className="notification__item__message">
                          <Typography.Paragraph
                            ellipsis={{
                              rows: 5,
                              expandable: true,
                            }}
                            title="Содержимое уведомления"
                          >
                            {item.content}
                          </Typography.Paragraph>
                        </div>
                        <div className="notification__item__time">{dateFormat(item.createdAt)}</div>
                      </div>
                    )}
                  />
                </List.Item>
              </div>
            )}
          />
        </div>
      </InfiniteScroll>
    </Drawer>
  )
}

export default NotificationDrawer
