

export enum UserRole {
  client = 'ROLE_CLIENT',
  user = 'ROLE_USER',
  admin = 'ROLE_ADMIN',
}

type Actions = 'get' | 'put' | 'post' | 'delete'

