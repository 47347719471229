import businessOutline from './services/businessOutline'
import notification from './services/notification'
import user from './services/user'
import serviceDomain from './services/serviceDomain'


const API = {
  businessOutline,
  user,
  notification,
  serviceDomain,
}

export default API
