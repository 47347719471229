import React, { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { notification } from 'antd'
import { INotification, NotifyType } from '../../../../config/services/notification'

const NotificationPopup:React.FC<{notifications: INotification[]}> = ({ notifications }) => {
  const [showedIds, setShowedIds] = useLocalStorage<number[]>('notifiocations.popup.showed', [])

  useEffect(() => {
    const showNowIds:number[] = []

    notifications.forEach((item) => {
      if (!showedIds.includes(item.id) && !item.seen) {
        const config = {
          message: item.title,
          description: item.content,
        }
        switch (item.type) {
          case NotifyType.Error:
            notification.error(config)
            break
          case NotifyType.Success:
            notification.success(config)
            break
          case NotifyType.Info:
          default:
            notification.info(config)
            break
        }

        showNowIds.push(item.id)
      }
    })

    setShowedIds([...showedIds, ...showNowIds].filter((x) => x > 0))
  }, [notifications])

  return null
}

export default NotificationPopup
