import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit'
import app from './app'
import { injectStore } from '../config/client'

export const store = configureStore({
  reducer: {
    app,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(logger)
})

// @todo: удалить эти inject функции
injectStore(store)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
