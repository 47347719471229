import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'

export const defaultLanguage = 'ru'

const resources = {
    en: {},
    ru: {},
}

export default i18next
    .use(initReactI18next)
    .init({
        // lng: 'en',
        fallbackLng: defaultLanguage,
        debug: true, // TODO: включение дебага
        resources,
    }).then(() => {
        // console.log('initialized and ready to go!')
    })
