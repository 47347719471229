import jwtDecode, {JwtPayload} from 'jwt-decode'
import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {UserRole} from '../config/security'
import {axiosInstance, injectLogout, injectRefresh, readCookie} from '../config/client'
import API from '../config'


/**
 * Данные в localStorage
 * app.login - логин авторизованного пользователя
 * app.role - роль авторизованного пользователя
 * app.isLoggedIn - флаг авторизации.
 * app.docs - Дока swagger
 * app.subsidiary.id - текущий филиал
 *
 * Данные в cookie
 * app.token - токен авторизованного пользователя
 * app.refreshToken - refresh токен авторизованного пользователя
 * app.expiresIn - Время жизни токена
 */

const namespace = 'app'

export const setLayoutState = createAction<IAppLayoutState>(`${namespace}/set_layout_state`)
export const setControlState = createAction<IAppControlState>(`${namespace}/set_control_state`)
export const setMobileLayout = createAction<boolean>(`${namespace}/set_mobile_layout`)
const setDocs = createAction(`${namespace}/set_docs`)

export const setUser = createAction<IAppUserState>(`${namespace}/set_user_state`)

export const refreshTokenThunk = createAsyncThunk(
  `${namespace}/refresh`,
  async ({token}: { token: string }) => await API.user.token.refresh(token)
    .then((response) => {
      const decoded = jwtDecode<TokenJwtPayload>(response.data.token)
      document.cookie = `app.token=${response.data.token}`
      document.cookie = `app.refreshToken=${response.data.refreshToken}`
      document.cookie = `app.expiresIn=${decoded?.exp}`

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.token}`

      return {
        token: response.data.token,
        expiresIn: decoded?.exp,
        refreshToken: response.data.refreshToken,
      }
    })
)

export const initAuth = createAsyncThunk(
  `${namespace}/init_auth`,
  async (_, thunkAPI) => {
    const login = localStorage.getItem('app.login')
    if (!login) {
      return thunkAPI.rejectWithValue('not found login')
    }

    const token = readCookie('app.token')
    if (!token) {
      return thunkAPI.rejectWithValue('not found token')
    }

    const expiresIn = readCookie('app.expiresIn')
    const refreshToken = readCookie('app.refreshToken')
    if (!refreshToken) {
      return thunkAPI.rejectWithValue('not found refreshToken')
    }

    const decoded = jwtDecode<TokenJwtPayload>(token)
    const role = decoded?.roles.pop()

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`

    // обновление токена если експайренс протух
    if (Date.now() > Number(expiresIn)) {
      await thunkAPI.dispatch(refreshTokenThunk({token: refreshToken}))
    }


    thunkAPI.dispatch(setDocs(JSON.parse(`${localStorage.getItem('app.docs')}`)))

    return {
      token,
      expiresIn: decoded?.exp,
      refreshToken,
      login,
      role,
    }
  },
)

interface TokenJwtPayload extends JwtPayload {
  roles: UserRole[],
  client_id?: number,
  name?: string,
  surname?: string,
  patronymic?: string,
}

export const login = createAsyncThunk(
  `${namespace}/login`,
  async ({
           username,
           password,
         }: { username: string, password: string }, thunkAPI) => API.user.token.auth(username, password)
    .then(async (response) => {
      const decoded = jwtDecode<TokenJwtPayload>(response.data.token)
      const role = decoded?.roles.pop()
      console.log(response.data)

      document.cookie = `app.token=${response.data.token}`
      document.cookie = `app.refreshToken=${response.data.refreshToken}`
      document.cookie = `app.expiresIn=${decoded?.exp}`

      localStorage.setItem('app.login', username)
      localStorage.setItem('app.role', role ?? '')
      localStorage.setItem('app.isLoggedIn', '1')

      axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.token}`

      await thunkAPI.dispatch(initAuth())

      return {
        token: response.data.token,
        expiresIn: decoded?.exp,
        refreshToken: response.data.refreshToken,
        login: username,
        role,
      }
    }),
)

export const logout = createAsyncThunk(
  `${namespace}/logout`,
  async () => {
    window.localStorage.removeItem('app.login')
    window.localStorage.removeItem('app.role')
    window.localStorage.removeItem('app.isLoggedIn')
    window.localStorage.removeItem('app.docs')
    document.cookie = 'app.token; expires=Thu, 01-Jan-1970 00:00:01 GMT;'
    document.cookie = 'app.refreshToken; expires=Thu, 01-Jan-1970 00:00:01 GMT;'
    document.cookie = 'app.expiresIn; expires=Thu, 01-Jan-1970 00:00:01 GMT;'
    return null
  },
)

// @todo: удалить эти inject функции
injectLogout(logout)
injectRefresh(refreshTokenThunk)

export enum navTheme {
  DARK = 'realDark',
  LIGHT = 'light'
}

interface IAppUserState {
  isLoggingIn: boolean, // идет процесс авторизации
  isLoggedIn: boolean, // пользователь авторизован
  login: string,
  role: UserRole | null,
  auth: {
    token: string | null,
    expiresIn: string | null,
    refreshToken: string | null,
  },
  error: string | null,
}

interface IAppLayoutState { // настройки внешнего вида Partial<ProSettings>
  navTheme?: 'realDark' | 'light' | undefined,
  fixSidebar?: boolean,
  layout?: 'side' | 'top' | 'mix',
  splitMenus?: boolean, // Работает только в mix режиме

  colorPrimary: string,
  contentWidth: 'Fluid' | 'Fixed',
  tab: 'theme',

  headerRender: false | undefined,
  isMenuTop: boolean,
  menuCollapsed: boolean,
  menuShadow: boolean,
  menuRender: false | undefined,
  themeLight: boolean,
  squaredBorders: boolean,
  borderLess: boolean,
  fixedWidth: boolean,
}

interface IAppControlState {
  serviceAddition: boolean,
}

interface IAppState {
  isMobile: boolean | undefined,
  isLoading: boolean,
  settings: { // настройки
    layoutState: IAppLayoutState,
    controlSettings: IAppControlState,
  },
  docs: {
    isLoad: boolean,
    data: any | null,
    error: string | null
  },
  user: IAppUserState,
}

const initialState: IAppState = {
  isMobile: false,
  isLoading: false,
  settings: {
    layoutState: {
      navTheme: navTheme.LIGHT,
      fixSidebar: false,
      layout: 'mix',
      splitMenus: false, // Работает только в mix режиме

      colorPrimary: '#1890ff',
      contentWidth: 'Fluid',
      tab: 'theme',

      isMenuTop: false,
      menuCollapsed: false,
      menuShadow: false,
      headerRender: undefined,
      menuRender: undefined,
      themeLight: true,
      squaredBorders: false,
      borderLess: true,
      fixedWidth: false,
    },
    controlSettings: {
      serviceAddition: true,
    },
  },
  docs: {
    isLoad: false,
    data: null,
    error: null,
  },
  user: {
    isLoggingIn: false,
    isLoggedIn: false,
    login: '',
    role: UserRole.client,
    auth: {
      token: readCookie('app.token'),
      expiresIn: readCookie('app.expiresIn'),
      refreshToken: readCookie('app.refreshToken'),
    },
    error: null,
  },
}

const appSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload
    })

    builder.addCase(setLayoutState, (state, action) => {
      state.settings.layoutState = action.payload
    })
    builder.addCase(setControlState, (state, action) => {
      state.settings.controlSettings = action.payload
    })
    builder.addCase(setMobileLayout, (state, action) => {
      state.isMobile = action.payload
    })
    // swagger docs
    builder.addCase(setDocs, (state, action) => {
      state.docs.data = action.payload
    })
    // login
    builder.addCase(login.pending, (state) => {
      state.user = {
        isLoggingIn: true,
        isLoggedIn: false,
        login: '',
        role: null,
        auth: {
          token: null,
          expiresIn: null,
          refreshToken: null,
        },
        error: null,
      }
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: true,
        login: action.payload.login,
        role: action.payload.role ?? null,
        auth: {
          token: action.payload.token,
          expiresIn: `${action.payload.expiresIn}`,
          refreshToken: action.payload.refreshToken,
        },
        error: null,
      }
    })
    builder.addCase(login.rejected, (state, action) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: false,
        login: '',
        auth: {
          token: null,
          expiresIn: null,
          refreshToken: null,
        },
        error: action.error.message ?? '',
      }
    })
    builder.addCase(initAuth.pending, (state) => {
      state.user = {
        ...state.user,
        isLoggingIn: true,
      }
    })
    builder.addCase(initAuth.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: true,
        login: action.payload.login,
        role: action.payload.role ?? null,
        auth: {
          token: action.payload.token,
          expiresIn: `${action.payload.expiresIn}`,
          refreshToken: action.payload.refreshToken,
        },
        error: null,
      }
    })
    builder.addCase(initAuth.rejected, (state) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: false,
        login: '',
        auth: {
          token: null,
          expiresIn: null,
          refreshToken: null,
        },
      }
    })
    // refresh
    builder.addCase(refreshTokenThunk.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        auth: {
          token: action.payload.token,
          expiresIn: `${action.payload.expiresIn}`,
          refreshToken: action.payload.refreshToken,
        },
        error: null,
      }
    })
    builder.addCase(refreshTokenThunk.rejected, (state, action) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: false,
        login: '',
        auth: {
          token: null,
          expiresIn: null,
          refreshToken: null,
        },
        error: action.error.message ?? '',
      }
    })

    builder.addCase(logout.fulfilled, (state) => {
      state.user = {
        ...state.user,
        isLoggingIn: false,
        isLoggedIn: false,
        login: '',
        role: null,
        auth: {
          token: null,
          expiresIn: null,
          refreshToken: null,
        },
      }
      state.docs.data = null
    })
  },
})

export default appSlice.reducer
