import axios from 'axios'
import {endpoint} from '../endpoint'
import {axiosInstance} from '../client'

interface IAuthResponse {
  token: string,
  refreshToken: string
}

export default {
  token: {
    auth: async (login: string, password: string) => await axiosInstance.post<IAuthResponse>(
      endpoint.login,
      {
        login,
        password,
      },
    ),
    refresh: async (token: string) => {
      const data = new FormData()
      data.append('refreshToken', token)

      return await axiosInstance<IAuthResponse>({
        method: 'post',
        url: endpoint.refreshToken,
        data,
      })
    },
  },

}
